import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import { Svg } from 'kh-common-components';
import { LinkModal } from 'kh-common-components';

import Faq from 'components/common/Faq';
import Link from 'components/common/Link';
import Image from 'components/common/Image';

import AwardsInfo from 'components/cards/AwardsInfo';
import NgSwitchInfo from 'components/cards/NgSwitchInfo';

import faqData from 'constants/components/accordion-list/employee-discount-offer-faq';
import { modalContents } from 'constants/modal-content';

// asset images
// https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/
import heroBanner from 'images/wa-seniors/hero-mug.png';
import logoWASeniors from 'images/wa-seniors/logo-waseniors.png';

import 'styles/pages/waseniors.css';

function WASeniorsPage() {

    const waSeniorsDisc = '39';
    const waSeniorsDiscCode = 'WASENIORS';
	const faqSettings = {openFirstItem: true};
    const svgStyle = { transform: 'scale(1.23)' };

    // add dynamic accordionList to faqData
	const accordionList = [{
        "title": "I'm already a Kleenheat customer, can I redeem this offer?",
        "content": `<p>As a WA Seniors Card member who is an existing Kleenheat customer, we are pleased to offer you <strong>${waSeniorsDisc}% off natural gas usage charges for 12 months*</strong>. Simply call our customer service team on <a href="tel:132180" title="Contact us">13 21 80</a>, and we’ll increase the discount on your account.</p>`
    }];
    const modifiedAccordionList = accordionList.concat(faqData);


	return (
		<>
            <SEO title="WA Seniors Card members save big on natural gas | Kleenheat" 
                 titleBespoke="true"
            	 description="WA Seniors Card members can enjoy bigger savings on natural gas, thanks to our exciting local partnership."
        	/>
            <Header />
            <LayoutMain>

            	{/* hero banner */}
                <LayoutPanel background="mer-bg--primary-cyan"                 
                             padding="mer-py-ju"
                             theme="mer-theme--cyan">
                    <div className="container">
                        <div className="row mer-panel justify-content-around align-items-middle">

                                <div className="col-10 col-sm-7 col-lg-6 mer-text--size-lg">
                                    <div className="mer-svg-logo--inline-kleenheat">
                                        <Svg title="Kleenheat logo"
                                             icon="kleenheat-logo"
                                             col="logo-kleenheat"
                                        /> 
                                    </div>

                                    <div className="content">
                                        <h1>Enjoy big gas savings with WA Seniors</h1>
                                        <p>We're offering WA Seniors Card members <strong>{waSeniorsDisc}% off natural gas usage charges for 12 months*.</strong></p>
                                        <div className="mer-panel__actions mer-button-group">
                                            <Link text="Switch now"
                                            link={`/sign-up/promo/${waSeniorsDiscCode}`}
                                            linkClass="mer-button mer-button--primary" />

                                            <Link text="Existing customer?"
                                            link="#faq"
                                            linkClass="mer-button mer-button--secondary" />
                                        </div>
                                    </div>
                                </div>

                                <div className="mer-panel__images col-8 col-sm-5 col-lg-4 col-xl-6">
                                    <img src={heroBanner} 
                                        className="mer-panel__image product-flourish"
                                        alt="Cup of tea" />
                                </div>
                        </div>
                    </div>
                </LayoutPanel>

                <LayoutPanel theme="mer-theme--light"
	        				 background="mer-bg--ui-light"
		                	 padding="mer-pt-ju mer-pb-sm"
		                	 id="offer">
		            <div className="container">
		            	<div className="row mer-panel align-items-center justify-content-center">
		            		<div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg"> 
			                	<div className="content text-center">
                                    <img src={logoWASeniors} 
                                         className="mer-panel__image logo-waseniors"
                                         alt="WA Seniors Logo" />
		                			<h2 className="mt-2 mer-color--primary-cyan">Member offer</h2> 
	                			</div> 
                			</div>
		            	</div>
		            </div>
		        </LayoutPanel>

                {/* Happy deal for WA Seniors card */}
                <LayoutPanel background="mer-bg--ui-light"
                             padding="mer-pt-de mer-pb-lg">
                    <div className="container">
                        <div className="row mer-panel">
                            <div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
                                <Svg title="Discount reminder flowmoji"
                                     col="mer-panel__image mer-fill--primary-navy"
                                     icon="flowmoji-discountreminder"
                                     style={ svgStyle }
                                />
                            </div>
                            <div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
                                <h3>A happy deal for WA Seniors card members</h3>
                                <p>We're committed to offering WA Seniors card members <strong>{waSeniorsDisc}% off natural gas usage charges for 12 months*.</strong></p>
                                <div>                  
                                <small><LinkModal
                                    modalContents={modalContents}
                                    useModal="true"
                                    title="WA Seniors offer terms and conditions"
                                    // linkClass=""
                                    displayContent="*Terms and conditions apply"
                                    modalTitle="Terms and Conditions"
                                    modalContentName="waseniors-terms"
                                    okayButtonLabel="Okay"
                                /></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutPanel>
                
                {/* WA local */}
                <LayoutPanel background="mer-bg--ui-light"
                             padding="mer-py-de">
                    <div className="container">
                        <div className="row mer-panel">
                            <div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
                                <Svg title="Western Australia highlighted on Australia map"
                                    col="mer-panel__image"
                                    icon="flowmoji-wa"
                                    style={ svgStyle }
                                />
                            </div>
                            <div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
                                <h3>We’re WA locals</h3>
                                <p>We’re a WA born and bred company, with a track record of delivering genuine, ongoing value to households across the state.</p>
                                <p>We’re passionate about giving back and supporting the communities that support us.</p>
                            </div>
                        </div>
                    </div>
                </LayoutPanel>

                {/* Flybuys info */}
                <LayoutPanel background="mer-bg--ui-light"
                             padding="mer-pt-de mer-pb-ju">
                    <div className="container">
                        <div className="row mer-panel">
                            <div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">
                                <Image className="mer-panel__image"
                                        src="/flybuys-points-1.png"
                                        alt="Flybuys 1 point per $1 spent"
                                    />
                            </div>
                            <div className="content col-10 col-xl-5 col-md-7 col-sm-7 offset-sm-1 offset-lg-0">
                                <h3>Earn Flybuys points</h3>
                                <p>Simply link your Flybuys number with your Kleenheat natural gas account, and you’ll earn one point for every dollar spent on gas*.</p>
                                <div>                  
                                    <small><LinkModal
                                        modalContents={modalContents}
                                        useModal="true"
                                        title="Flybuys terms & conditions"
                                        // linkClass=""
                                        displayContent="*Terms and conditions apply"
                                        modalTitle="Flybuys terms & conditions"
                                        modalContentName="flybuys-terms"
                                        okayButtonLabel="Okay"
                                    /></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </LayoutPanel>
				
				<AwardsInfo panelBackground="mer-bg--gs-grey-lighter" 
                    panelPadding="mer-py-de" />

                <Faq panelPadding="mer-py-lg"
		  			 data={modifiedAccordionList}
					 id="faq"
					 settings={faqSettings} />

                <NgSwitchInfo theme="mer-theme--cyan"
    						  panelBackground="mer-bg--primary-cyan"
	            			  panelPadding="mer-py-lg"
	            			  promoCode={waSeniorsDiscCode} />

		    </LayoutMain>
			<Footer />
		</>
	)
}

export default WASeniorsPage;